<template>
	<div>
		<qy-top-nav :title="shopInfo.shopName" />
		<van-swipe class="my-swipe" indicator-color="#fff" v-show="shopInfo.bannerImgs.length > 0">
			<van-swipe-item v-for="(banner, index) in shopInfo.bannerImgs">
				<template v-if="banner.url.indexOf('.mp4') > -1">
					<video :ref="'video-' + index" class="video-js" style="width: 100%; height: 100%; max-height: 200px" preload="true" controls :poster="banner.poster">
						<source :src="banner.url" type="video/mp4" />
					</video>
					<!-- <van-icon class="playIcon" name="play-circle-o" @click="playVideo(index)"/> -->
				</template>
				<img v-else :src="banner.url" />
			</van-swipe-item>
		</van-swipe>
		<div style="height: 30px" v-show="shopInfo.bannerImgs.length <= 0"></div>
		<div class="proinfo">
			<div style="display: flex; padding: 10px 10px 0px 10px; align-items: flex-start">
				<div style="width: 50px; height: 50px; border: 1px solid #ededed; overflow: hidden; border-radius: 50px">
					<van-image style="width: 50px; height: 50px" :src="getImgUrl(shopInfo.shopLogo)" @click="gotoShop(shopInfo.shopId)" />
				</div>
				<div style="flex-grow: 1; padding-left: 8px">
					<p style="margin: 0px 0 5px 4px; font-weight: bold; font-size: 17px" :style="{ color: shopInfo.level == 3 ? '#D51C20' : '#000' }">
						{{ shopInfo.shopName }}
					</p>
					<div>
						<span style="font-size: 13px; color: #999999; background: #f7f7f7; margin: 0 4px; padding: 2px 4px" v-for="tag in shopInfo.tags">{{ tag }}</span>
					</div>
					<div style="padding: 5px 2px; font-size: 14px; color: #ccc">{{ shopInfo.viewNum }}次浏览</div>
				</div>
				<!-- <div >
					<a href="javascript:;" @click="showPoster=true" class="flex-column"><van-icon name="share" color="black"></van-icon><span style="display:block;color:#333;">分享</span></a>
				</div> -->
				<!-- <van-image :src="imgs['level' + shopInfo.level]" style="width: 40px; height: 40px" /> -->
			</div>
		</div>

		<div style="position: fixed; top: 0; height: 100%; width: 100%; z-index: 999999" v-show="showPoster">
			<!-- <va    -->
			<div style="position: absolute; width: 100%; height: 100%; background: #000; opacity: 0.5" @click="showPoster = false"></div>
			<van-image
				id="posterContentImg"
				ref="posterContentImg"
				style="width: 80%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
				:src="posterContentImg"
			/>
			<div style="position: absolute; text-align: center; bottom: 12px; width: 100%; color: #fff; font-size: 13px">长按图片分享</div>
		</div>

		<van-tabs v-model="active" style="margin-top: 10px">
			<!-- <van-tab title="商品列表" :style="{height:containerHeight,overflow:'auto'}"> -->
			<van-tab title="商品列表">
				<goodsList :shopId="shopInfo.shopId"></goodsList>
			</van-tab>
			<!-- <van-tab title="店铺信息" :style="{height:containerHeight,overflow:'auto'}"> -->
			<van-tab title="厂家信息">
				<div style="padding: 12px 10px; color: #333">
					{{ shopInfo.shopIntro }}
				</div>
				<div style="padding: 10px">
					<img v-for="detailImg in shopInfo.detailImgList" :src="getImgUrl(detailImg)" style="width: 100%" />
				</div>
			</van-tab>
		</van-tabs>
		<van-goods-action v-if="showApply">
			<van-goods-action-button type="warning" text="申请为店铺经销商" @click="applyShopDealer" />
		</van-goods-action>
		<!--经销商-->
		<van-popup v-model="showDealer" position="bottom">
			<van-picker
				title="选择经销商资料"
				show-toolbar
				:loading="loading"
				cancel-button-text="关闭"
				:columns="dealerList"
				@confirm="onConfirm"
				@cancel="onCancel"
				@change="onChange"
			/>
		</van-popup>
		<div style="position: fixed; bottom: 0px; height: 30px; background: rgb(255, 255, 255); right: 0px; left: 0px; padding: 10px; overflow: hidden" v-show="active==1">
			<div class="lefticon" style="width: 50%; float: left; width: 50px; text-align: center">
				<a href="javascript:;" @click="showPoster = true">
					<i class="van-icon van-icon-share" style="color: #333; display: block"></i>
					<span style="display: block; color: #333">分享</span>
				</a>
			</div>
			<!-- <div class="lefticon" style="width: 50%; float: left; margin-left: 5px; width: 50px; text-align: center">
				<a href="javascript:;" @click="navigateTo('/shoppingCart')">
					<i class="van-icon van-icon-cart" style="color: #333; display: block"></i>
					<span style="display: block; color: #333">购物车</span>
				</a>
			</div> -->
			<div class="righticon"   style="width: 50%; float: right; text-align: right">
				<template v-if="isLogin()">
					<a style=" color: #fff; width: 100px; text-align: right; display: inline-block; padding: 0px 5px; border-radius: 20px; background: #d51c20; line-height: 35px; text-align: center; " :href="'tel:' + shopInfo.remark1" >
						<i class="van-icon van-icon-phone" style="position: relative; left: -4px; top: 2px"></i>
						电话咨询
					</a>
				</template>
				<template v-if="!isLogin()">
					<a style=" color: #fff; width: 100px; text-align: right; display: inline-block; padding: 0px 5px; border-radius: 20px; background: #d51c20; line-height: 35px; text-align: center; " @click="checkLogin()">
						<i class="van-icon van-icon-phone" style="position: relative; left: -4px; top: 2px"></i>
						电话咨询
					</a>
				</template>
				
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
import request from '@/utils/request';
import { Toast } from 'vant';
import goodsList from '../goodsList/goodsList';
import level1Png from '@/assets/index/level1.png';
import level2Png from '@/assets/index/level2.png';
import level3Png from '@/assets/index/level3.png';
import ballPng from '@/assets/index/ball.png';
import posterBg from '@/assets/img/posterBg.jpg';
import wx from 'weixin-js-sdk';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode';
export default {
	components: {
		goodsList: goodsList
	},
	data() {
		return {
			imgs: {
				level1: level1Png,
				level2: level2Png,
				level3: level3Png,
				ballPng: ballPng,
				posterBg: posterBg
			},
			smsText: '验证码',
			showPoster: false,
			genShareImg: false,
			QRImgUrl: '',
			posterContentImg: '',
			active: 0,
			loading: false,
			showDealer: false,
			showApply: false,
			clientWidth: 0,
			containerHeight: 0,
			dealerList: [],
			shopInfo: {
				shopId: null,
				shopName: '店铺信息',
				bannerImgs: [],
				shopDesc: ''
			},
			applyInfo: {
				contactPhone: '',
				contact: '',
				code: '',
				shopId: ''
			},
			times: 0,
			timer: null,
			disableSms: false,
			wxApiFlag: 0,
			shopFlag: 0,
			wxConfig: null,
			videoObj: {},
			playerOptions: {}
		};
	},
	created() {
		let shopId = (this.shopInfo.shopId = this.getUrlParam('id'));
		this.active = parseInt(this.getUrlParam('t', '0'));
		request.post('/api/shop/info', {id: shopId}).then((rs) => {
				if (rs.success) {
					let bannerImgArr = JSON.parse(rs.data.bannerImgs || '[]');
					if (bannerImgArr) {
						let bannerImgs = (rs.data.bannerImgs = []);
						for (let i = 0; i < bannerImgArr.length; i++) {
							let bannerInfo = {
								url: this.getImgUrl(bannerImgArr[i])
							};
							if (bannerImgArr[i].indexOf('mp4') > -1) {
								let nextItem = this.getImgUrl(bannerImgArr[i + 1]);
								if (nextItem && nextItem.indexOf('mp4') < 0) {
									bannerInfo.poster = nextItem;
									i++;
								}
							}
							bannerImgs.push(bannerInfo);
						}
					}
					let shopDesc = JSON.parse(rs.data.shopDesc);
					// console.log(shopDesc)
					rs.data.detailImgList = shopDesc.imgList || [];
					rs.data.tags = (rs.data.tags || '').split(',');
					for (let i = rs.data.tags.length - 1; i >= 0; i--) {
						if (!rs.data.tags[i]) {
							rs.data.tags.splice(i, 1);
						}
					}
					this.shopInfo = rs.data;
				} else {
					this.Toast(rs.msg);
				}
			});

		this.doPost('/api/share/shop',{shopId: shopId,url: location.href},(rs) => 
			{
				if (rs.success) {
					this.posterContentImg = 'data:image/png;base64,' + rs.data;
				}
			}
		);
		//-1 未登录 0 登录未申请 1登录已申请
		/* request.post("/api/shopDealer/isApplyShopDealer", {shopId: shopId}).then(rs => {let b = rs.data;if (b == 1 || b == -1) {this.showApply = false;} else {this.showApply = true;}}); */
		this.containerHeight = document.documentElement.clientHeight - 90 + 'px';
		// QRCode.toDataURL(location.href,{errorCorrectionLevel: 'L',margin: 2,width: 128},(err, url) => {if (err) {return;}this.QRImgUrl = url;});
		this.updateWxShareConfig();
	},
	mounted(){
	},
	methods: {
		updateWxShareConfig(){
			this.refreshWxConfig(wx,()=>{
				let shopInfo = this.shopInfo;
				let share_config = {
					imgUrl: location.origin + shopInfo.shopLogo, // 这里是需要展示的图标
					desc: shopInfo.shopIntro, // 这是分享展示的摘要
					title: shopInfo.shopName, // 这是分享展示卡片的标题
					link: location.href, // 这里是分享的网址
					success: function(res) {
						//alert(JSON.stringify(res));
					}
				}
				wx.updateAppMessageShareData(share_config); // 自定义“分享给朋友”及“分享到QQ”
				wx.updateTimelineShareData(share_config); // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
			});
		},
		sendPhoneCode() {
			if (this.disableSms) {
				return;
			}
			let contactPhone = this.applyInfo.contactPhone;
			if (!contactPhone) {
				Toast.fail('手机号码不能空');
				return;
			}
			let numbers = /^1\d{10}$/;
			if (!numbers.test(contactPhone) || contactPhone.length == 0) {
				Toast.fail('手机号码格式错误');
				return;
			}
			this.disableSms = true;
			Toast.loading();
			request
				.post('/api/common/sendPhoneCode', {
					phone: contactPhone,
					type: 'applyDemo'
				})
				.then(
					(response) => {
						Toast.clear();
						this.startTask(response.data);
						if (response.code != 0) {
							Toast.fail(response.msg);
						}
					},
					(response) => {
						this.startTask(response.data);
					}
				);
		},
		share() {
			if (this.genShareImg) {
				this.showPoster = true;
				return;
			}
			html2canvas(this.$refs.posterContent, {
				windowWidth: document.body.scrollWidth
			})
				.then((canvas) => {
					let dataURL = canvas.toDataURL('image/png');
					// console.log(dataURL)
					this.posterContentImg = dataURL;
					this.genShareImg = true;
					this.showPoster = true;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		sendApply() {
			Toast.loading();
			request.post('/api/common/applyDemo', this.applyInfo).then((res) => {
				Toast.clear();
				Toast.success('申请成功,请耐心等耐!');
			});
		},
		startTask(time) {
			let that = this;
			that.times = time || 300;
			that.timer = setInterval(function () {
				that.times--;
				if (that.times <= 0) {
					that.smsText = '验证码';
					clearInterval(that.timer);
					that.times = 60;
					that.disableSms = false;
				} else {
					that.smsText = that.times + '秒';
					that.disableSms = true;
				}
			}, 1000);
		},
		applyShopDealer() {
			let isLogin = !this.isLogin();
			if (!isLogin) {
				return;
			}
			this.loading = true;
			request
				.post('/api/dealer/list', {
					payStatus: 1
				})
				.then((res) => {
					this.loading = false;
					if (res.code == 0) {
						let list = res.data;
						if (list && list.length > 0) {
							this.dealerList = [];
							list.forEach((item) => {
								this.dealerList.push({
									text: item.dealerName,
									dealerId: item.id
								});
							});
							this.showDealer = true;
						} else {
							this.Dialog.confirm({
								title: '提示',
								message: '您尚未添加经销商资料,是否现在添加？'
							}).then(() => {
								this.$router.push('/addDealer');
							});
						}
					} else {
						return Toast.fail(res.msg);
					}
				});
		},
		onConfirm(value, index) {
			let dealerId = value.dealerId;
			let that = this;
			request
				.post('/api/shopDealer/add', {
					dealerId: dealerId,
					shopId: that.shopInfo.shopId
				})
				.then((res) => {
					if (res.code == 0) {
						that.showApply = false;
						return Toast.success('申请成功');
					} else {
						return Toast.fail(res.msg);
					}
				});
			this.showDealer = false;
		},
		onCancel() {
			this.showDealer = false;
		}
	}
};
</script>
<style type="text/css" scoped="">
.van-tab {
	font-size: 16px !important;
}

.my-swipe {
	margin: 0px;
	width: 100%;
	min-height: 200px;
}

.my-swipe img {
	width: 100%;
	max-height: 500px;
}

.van-swipe-item {
	text-align: center;
}

.shopCantainer {
	padding: 10px;
}

.van-goods-action-button {
	background: -webkit-linear-gradient(left, #ff6034, #ee0a24) !important;
	background: linear-gradient(to right, #ff6034, #ee0a24) !important;
}

/deep/.van-dialog__message {
	padding: 10px !important;
}

.shopDetail {
	line-height: 30px;
}

.shopDetail p {
	font-size: 15px;
	line-height: 30px;
}
</style>
<style>
.shopDetail img {
	max-width: 100%;
}

.shopDetail p {
	margin: 0px;
	font-size: 14px;
	padding: 5px 0px;
}

.proinfo {
	background: #fff;
	margin: 8px 0;
	border-radius: 5px;
	position: relative;
	margin-top: -20px;
	width: 95%;
	margin: -20px auto 0px auto;
}

.tabbox {
	overflow: hidden;
	borde-bottom: 1px solid #ededed;
}

.my-swipe .van-swipe__indicators {
	bottom: 32px;
}

.footertel {
	bottom: 0px;
	left: 0px;
	right: 0px;
	background: #fff;
	/* height: 66px; */
	padding: 0px 10px;
	border-top: 1px solid #ededed;
	overflow: hidden;
	line-height: 55px;
	padding-bottom: 50px;
}

.footertel .lefticon {
	float: left;
}

.footertel a {
	color: #666;
}

.footertel .righticon {
	float: right;
}

.footertel .righticon a {
	background: #d51c20;
	line-height: 40px;
	display: inline-block;
	border-radius: 4px;
	text-align: center;
	font-size: 14px;
}

.shopCantainer {
	padding: 10px;
}

.van-goods-action-button {
	background: -webkit-linear-gradient(left, #ff6034, #ee0a24) !important;
	background: linear-gradient(to right, #ff6034, #ee0a24) !important;
}

/deep/.van-dialog__message {
	padding: 10px !important;
}

.shopDetail {
	line-height: 30px;
}

.shopDetail p {
	font-size: 15px;
	line-height: 30px;
}

.shopDetail p {
	margin: 0px;
	font-size: 14px;
	padding: 5px 0px;
}

.proinfo {
	background: #fff;
	margin: 8px 0;
	border-radius: 5px;
	position: relative;
	margin-top: -20px;
	width: 95%;
	margin: -20px auto 0px auto;
}

.tabbox {
	overflow: hidden;
	borde-bottom: 1px solid #ededed;
}

.uniForm {
	overflow: hidden;
	border-bottom: 1px solid #ededed;
	padding-top: 10px;
}

.formlist {
	width: 100%;
	float: left;
	position: relative;
	height: 40px;
	line-height: 40px;
	margin-bottom: 10px;
}

.formlist:after {
	content: '';
	position: absolute;
	width: 1px;
	left: 60px;
	top: 10px;
	bottom: 10px;
	background: #ededed;
}

.formlist label {
	position: absolute;
	left: 10px;
	display: inline-block;
	font-size: 13px;
}

.formlist input {
	line-height: 35px;
	border: 1px solid #ededed;
	width: 100%;
	padding-left: 80px;
	font-size: 13px;
}

.buttonshow {
	border-radius: 40px;
	width: 80%;
	display: block;
	margin: 10px auto;
	text-align: center;
	border: 0px;
	line-height: 30px;
	background: linear-gradient(#d51c20, #ff7d80);
	color: #fff;
	font-size: 12px;
}

.yzmbtn {
	position: absolute;
	right: 10px;
	background: #d51c20;
	color: #fff !important;
	border-radius: 5px;
	padding: 0px 10px;
	top: 5px;
	line-height: 30px;
	font-size: 13px;
}

.messtip {
	font-size: 13px;
	clear: both;
	position: relative;
	min-height: 30px;
}

.messtip span {
	color: #d51c20;
	font-size: 13px;
}

.messtip label {
	width: 15px;
	height: 15px;
	display: block;
	border-radius: 30px;
	border: 1px solid #ededed;
	position: absolute;
	top: 0px;
	left: 10px;
}
</style>
